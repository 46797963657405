
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Pageniation as Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const app = namespace("app");
const base = namespace("base");
const consult = namespace("consult");
@Component({
  components: { FileUpload, Editor }
})
@Table("loadListData")
export default class ConsultView extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  // 资讯
  @consult.Action repeatTitle;
  @consult.Action insertContent;
  @consult.Action updateContent;
  @consult.Action queryContentClassPage;
  @consult.Action queryContentDetail;
  @consult.Action insertContentClass;
  @consult.Action releaseContent;
  @consult.Action withdrawContent;

  emptyToLine = emptyToLine;
  consultForm: any = {
    byLiner: "", //署名
    classCode: "", //资讯分类编码
    content: "", //资讯内容
    digest: "", //文章摘要
    release: false, //是否发布.true-发布,false-不发布(默认)
    releaseRangeList: [], //发布范围
    sketch: "", //缩略图.上传的数据不应该含有域名
    title: "" //资讯标题
  };
  contentRangeList: any[] = [];
  classSearchValue: string = "";
  checkRecordVisible: boolean = false;
  dayjs = dayjs;
  checkFrontImage(value, callback) {
    if (this.consultForm.bannerType === "default") {
      callback();
    } else {
      if (!value) {
        callback(new Error(t("v210831.please-upload-pictures")));
      } else {
        callback();
      }
    }
  }
  get breadData() {
    return [
      { name: t("v210831.information-management"), path: "/consult/consult-list" },
      {
        name: t("v210831.information-for-details")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "consultAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  defaultBannerList: any = [];
  init() {
    this.getContentDetail();
  }
  mounted() {}
  //   获取详情
  getContentDetail() {
    this.queryContentDetail({ contentCode: this.id }).then(data => {
      this.consultForm = data.data;
      this.classSearchValue = this.consultForm.className;
    });
  }
  //   编辑
  handleEdit() {
    this.$router.push("/consult/consult-edit/" + this.id);
  }
  //   发布
  handleRelease() {
    this.releaseContent({ contentCode: this.id }).then(() => {
      this.$message.success(t("v210831.published-successfully"));
      this.getContentDetail();
    });
  }
  //   下架
  handleWithdraw() {
    this.withdrawContent({ contentCode: this.id }).then(() => {
      this.$message.success(t("v210831.removed-successfully"));
      this.getContentDetail();
    });
  }
  //   查看操作记录
  checkRecord() {
    this.checkRecordVisible = true;
  }
}
